import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import React, { forwardRef, useEffect, useRef } from "react";
import { BsGearWideConnected } from "react-icons/bs";
import { Navbar, Container, Nav, Image } from "react-bootstrap";

import "./headingnavbar.styles.css";
import logo from "../../assets/img/BW-Logo.png";
import { useOurContext } from "../Context/OurContext";
import SideBarOptions from "../SideBarOptions/SideBarOptions";

const HeadingNavbar = ({ onClick }, ref) => {
  const location = useLocation();
  const navbarToggleRef = useRef(null);
  const { authenticatedUser } = useOurContext();

  useEffect(() => {
    if (navbarToggleRef) handleHideNavBarRef();
  }, [location, navbarToggleRef]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideHeading);

    return () => {
      document.removeEventListener("click", handleClickOutsideHeading);
    };
  }, []);

  const handleHideNavBarRef = () => {
    const navbar = navbarToggleRef.current;
    if (navbar) {
      const collapsed = isNavbarCollapsed();

      if (!collapsed) {
        navbarToggleRef.current.click();
      }
    }
  };

  const handleClickOutsideHeading = (e) => {
    if (
      navbarToggleRef.current &&
      !navbarToggleRef.current.contains(e.target)
    ) {
      handleHideNavBarRef();
    }
  };

  const isNavbarCollapsed = () => {
    if (navbarToggleRef.current) {
      return navbarToggleRef.current.classList.contains("collapsed");
    }
    return false;
  };

  return (
    <main
      ref={ref}
      style={{ width: "100%", position: "sticky", top: "0", zIndex: "5" }}
    >
      <Navbar expand="md" className="heading-navbar" sticky="top">
        <Container className="mx-md-0 px-md-4">
          <Navbar.Brand as={Link} to="/dashboard">
            <Image
              src={logo}
              style={{ width: "100px" }}
              className="img-fluid bg-light p-2 rounded"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            ref={navbarToggleRef}
            className="bg-light"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="hidden-y-scrollbar"
            style={{
              maxHeight: "calc(100vh - 80px)",
              overflowX: "hidden",
            }}
          >
            <Nav className="d-lg-none d-block d-sm-block d-md-none mb-3">
              <SideBarOptions onClick={onClick} />
              <div className="w-100 rounded mb-2 text-center text-light">
                <Link
                  to="/my-settings"
                  className="text-light"
                  style={{ textDecoration: "none" }}
                >
                  <span className="position-relative pointer-cursor">
                    Settings
                    <motion.div
                      className="p-2 position-absolute d-flex justify-content-center align-items-center"
                      style={{ top: "-5px", right: "-30px" }}
                      whileHover={{ rotate: 720, transition: { duration: 2 } }}
                    >
                      <BsGearWideConnected />
                    </motion.div>
                  </span>
                </Link>
                <div style={{ textTransform: "capitalize" }}>
                  {authenticatedUser ? authenticatedUser.user_access : ""}{" "}
                  Portal
                </div>
                <div style={{ fontSize: "12px" }}>
                  <Link
                    to="/privacy_policy"
                    className="text-light"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </main>
  );
};

export default forwardRef(HeadingNavbar);
