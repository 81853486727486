import React from "react";
import { NavLink as BootstrapLink, useLocation } from "react-router-dom";

const NavLink = ({ to, state = {}, redirectUrl, ...otherProps }) => {
  const location = useLocation();

  const redirectBackUrl =
    redirectUrl && redirectUrl ? redirectUrl : location.pathname;

  // Add redirectUrl to the state of the NavLink
  const navLinkState = {
    ...state,
    redirectUrl: redirectBackUrl,
  };

  return <BootstrapLink to={to} state={navLinkState} {...otherProps} />;
};

export default NavLink;
