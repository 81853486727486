import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfigObject;
let VAPID_KEY;
if (
  process.env.NODE_ENV === "production" &&
  document.location.href.includes("https://app.blindweight.com")
) {
} else {
  if (document.location.href.includes("https://staging.blindweight.com")) {
  } else {
    firebaseConfigObject = {
      apiKey: "AIzaSyDPjlEF92oyPM-ffcMt6BIQmwx3mxTMy0w",
      authDomain: "testblindweight.firebaseapp.com",
      projectId: "testblindweight",
      storageBucket: "testblindweight.appspot.com",
      messagingSenderId: "1092984007483",
      appId: "1:1092984007483:web:ed4f4aa7c322646a3a251e",
      measurementId: "G-8CFVEMNS8G",
    };
    VAPID_KEY =
      "BEc7Q4__tiC-iNHZ-IqPITi4ftLYd1ZwyJxa8e0znYbd7ND-LLNW4lDoxfUB6aUMlgpDDzbwFcvpqykc5bW4lW4";
  }
}
const firebaseConfig = firebaseConfigObject
  ? firebaseConfigObject
  : {
      apiKey: "AIzaSyDPjlEF92oyPM-ffcMt6BIQmwx3mxTMy0w",
      authDomain: "testblindweight.firebaseapp.com",
      projectId: "testblindweight",
      storageBucket: "testblindweight.appspot.com",
      messagingSenderId: "1092984007483",
      appId: "1:1092984007483:web:ed4f4aa7c322646a3a251e",
      measurementId: "G-8CFVEMNS8G",
    };

VAPID_KEY = VAPID_KEY
  ? VAPID_KEY
  : "BEc7Q4__tiC-iNHZ-IqPITi4ftLYd1ZwyJxa8e0znYbd7ND-LLNW4lDoxfUB6aUMlgpDDzbwFcvpqykc5bW4lW4";

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = async () =>
  (await isSupported()) && getMessaging(firebaseApp);
export const fireStoreDB = getFirestore();
export const firebaseVapidKey = VAPID_KEY;
