// Import the functions you need from the SDKs you need
import { fireStoreDB, firebaseVapidKey, messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const FCM_TOKEN_COLLECTION = "usersFcmTokens";

export async function requestPermission(uid) {
  console.log("Requesting permission...");
  if ("Notification" in window) {
    let permission = await Notification.requestPermission();
    console.log("permission: ", permission);
    if (permission === "granted") {
      console.log("Notification permission granted.");
      // get token and save in firestore
      await saveMessagingDeviceToken(uid);
    } else if (permission !== "denied") {
      permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("permission granted");
        await saveMessagingDeviceToken(uid);
      } else {
        console.log("Permission not granted.");
      }
    } else {
      console.log("Permission denied.");
    }
  }
}

export async function saveMessagingDeviceToken(uid, expiresAt) {
  const msg = await messaging();
  const fcmToken = await getToken(msg, {
    vapidKey: firebaseVapidKey,
  });

  if (fcmToken) {
    const tokenRef = doc(fireStoreDB, FCM_TOKEN_COLLECTION, uid);
    await setDoc(tokenRef, { user_id: uid, expiresAt, fcmToken: fcmToken });
  } else {
    requestPermission(uid);
  }
}

export const removeDeviceToken = async (user_id) => {
  await deleteDoc(doc(fireStoreDB, FCM_TOKEN_COLLECTION, user_id));
};

export const onMessageListener = () => {
  return new Promise(async (resolve) => {
    onMessage(await messaging(), (payload) => {
      resolve(payload);
    });
  });
};
