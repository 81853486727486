import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import React, { createContext, useContext, useState, useEffect } from "react";

import useNavigate from "../../hooks/useNavigate";
import { removeDeviceToken } from "../../config/firebase-messaging";
import { getUserDetails, getUserPreferences } from "../../API/apiCalls";

const OurContext = createContext();

export function OurContextProvider({ children }) {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies(["blind_weight"]);
  const [toastList, setToastList] = useState([]);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [myPreferences, setMyPreferences] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  const setUserPreferences = (preferences) => {
    setMyPreferences({ ...preferences });
  };

  const removeUserPreferences = () => {
    setMyPreferences(null);
  };

  const updateToastData = ({ data, delay = 3000 }) => {
    const newItemObject = {
      id: Date.now(),
      ...data,
    };
    setToastList((prevItems) => [newItemObject, ...prevItems]);

    setTimeout(() => removeToastItem(newItemObject.id), delay);
  };

  const removeToastItem = (itemId) => {
    setToastList((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const userTokenExpired = (decoded) => {
    return decoded.exp * 1000 < Date.now();
  };

  const loginAuthenticatedUser = async (user) => {
    if (!user) return;
    const data = await getUserDetails({ user_id: user.id });
    let userData = user;
    if (data) userData = { ...userData, ...data };
    setAuthenticatedUser({ ...userData });
  };

  const checkPreferences = async () => {
    const data = await getUserPreferences();
    if (data && data.user) setUserPreferences({ ...data.user });
  };

  const logoutAuthenticatedUser = () => {
    if (authenticatedUser) removeDeviceToken(authenticatedUser.id);
    removeUserPreferences();
    setAuthenticatedUser(null);
  };

  const checkUserAuthenticated = async () => {
    if (
      cookies.blind_weight &&
      cookies.blind_weight !== "undefined" &&
      cookies.blind_weight.path !== "/"
    ) {
      const token = cookies.blind_weight;
      // console.log("token: ", token);
      if (token) {
        const decoded = jwtDecode(token);
        // check authUser expired
        const tokenExpired = userTokenExpired(decoded);
        if (tokenExpired) {
          if (authenticatedUser) logoutAuthenticatedUser();
        } else {
          if (decoded) {
            // get authUser details
            const data = await getUserDetails({ user_id: decoded.id });
            if (data.user_access === "parent" && !data.payment_status) {
              removeCookie("blind_weight");
              logoutAuthenticatedUser();
              if (isAuthLoading) setIsAuthLoading(false);
              navigate("/login?payment_status=false");
            } else if (data) {
              let user_data = {
                ...data,
                ...decoded,
              };
              setAuthenticatedUser({ ...user_data });
              if (!myPreferences) await checkPreferences();
            }
          }
        }
      }
    }
    if (isAuthLoading) setIsAuthLoading(false);
  };

  useEffect(() => {
    checkUserAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OurContext.Provider
      value={{
        toastList,
        updateToastData,
        removeToastItem,
        myPreferences,
        setUserPreferences,
        removeUserPreferences,
        authenticatedUser,
        isAuthLoading,
        loginAuthenticatedUser,
        setAuthenticatedUser,
        logoutAuthenticatedUser,
      }}
    >
      {children}
    </OurContext.Provider>
  );
}

export const useOurContext = () => useContext(OurContext);
