import { Button, Nav } from "react-bootstrap";
import {
  FaUsers,
  FaSignInAlt,
  FaStream,
  FaHistory,
  FaUser,
  FaHeadphonesAlt,
  FaListAlt,
  FaDollarSign,
  FaRegChartBar,
  FaSlidersH,
  FaRss,
  FaBell,
} from "react-icons/fa";
import { BsStack, BsBoxArrowUpRight } from "react-icons/bs";

import { useOurContext } from "../Context/OurContext";
import NavLink from "../Common/NavLink";
import useCheckGroupAdmin from "../../hooks/useCheckGroupAdmin";

const navLinks = [
  {
    icon: FaUser,
    title: "Hi",
    text: "Hi",
    to: "/my-settings",
    allowed: ["admin", "provider", "parent", "group", "user"],
  },
  {
    icon: FaStream,
    title: "Dashboard",
    text: "Dashboard",
    to: "/dashboard",
    allowed: ["admin", "provider", "parent", "group", "user"],
  },
  {
    icon: BsStack,
    title: "Code Records",
    text: "Code Records",
    to: "/code-records",
    allowed: ["user"],
  },
  {
    icon: BsStack,
    title: "Code Records",
    text: "Code Records",
    to: "/code-records",
    allowed: ["group", "provider", "parent", "user"],
    groupAdminAllowed: true,
  },
  {
    icon: FaUsers,
    title: "Manage Users",
    text: "Manage Users",
    to: "/manage-users",
    allowed: ["admin"],
  },
  {
    icon: FaUsers,
    title: "Manage Admin Users",
    text: "Manage Admins",
    to: "/manage-admins",
    allowed: ["admin"],
  },
  {
    icon: FaUsers,
    title: "Group Users",
    text: "Users",
    to: "/users",
    allowed: ["group", "provider", "parent", "user"],
    groupAdminAllowed: true,
  },
  // {
  //   icon: FaDollarSign,
  //   title: "User Credit Details",
  //   text: "Credits",
  //   to: "/credits",
  //   allowed: ["user"],
  // },
  {
    icon: FaHistory,
    title: "User Access Logs",
    text: "Access Logs",
    to: "/access-logs",
    allowed: ["admin"],
  },
  {
    icon: FaRegChartBar,
    title: "User Activity Statistics",
    text: "Statistics",
    to: "/statistics",
    allowed: ["admin"],
  },
  {
    icon: FaListAlt,
    title: "Manage Terms & Conditions",
    text: "Manage Terms",
    to: "/manage-terms",
    allowed: ["admin"],
  },
  {
    icon: BsBoxArrowUpRight,
    title: "Manage Popups",
    text: "Manage Popups",
    to: "/manage-popups",
    allowed: ["admin"],
  },
  {
    icon: FaRss,
    title: "Broadcast Email",
    text: "Broadcast",
    to: "/broadcast",
    allowed: ["admin"],
  },
  {
    icon: FaSlidersH,
    title: "Preferences",
    text: "Preferences",
    to: "/preferences",
    allowed: ["admin", "parent", "provider", "group"],
  },
  {
    icon: FaBell,
    title: "Notifications",
    text: "Notifications",
    to: "/notifications",
    allowed: ["admin", "parent", "provider", "group", "user"],
  },
  {
    icon: FaHeadphonesAlt,
    title: "Request Support",
    text: "Support",
    to: "/support",
    allowed: ["admin", "parent", "provider", "group", "user"],
  },
];

const SideBarOptions = ({ onClick }) => {
  const { authenticatedUser } = useOurContext();
  const { name, user_name, user_access } = authenticatedUser;
  const { groupAdmin, isLoading: isCheckingIfAdmin } = useCheckGroupAdmin();

  return (
    <>
      {!isCheckingIfAdmin && authenticatedUser && (
        <>
          {navLinks.map((navOption, idx) => {
            const navText =
              navOption.text === "Hi"
                ? `${navOption.text} ${
                    user_name ? user_name : name.split(" ")[0]
                  }`
                : navOption.text;
            const navTitle =
              navOption.title === "Hi"
                ? `${navOption.title} ${
                    user_name ? user_name : name.split(" ")[0]
                  }`
                : navOption.title;
            const showNavTab = navOption.allowed.includes(user_access)
              ? navOption.groupAdminAllowed === true && groupAdmin !== true
                ? false
                : navOption.allowed.includes(user_access)
              : false;

            return showNavTab ? (
              <NavLink
                key={idx}
                to={navOption.to}
                className="d-flex align-items-center w-100 rounded mb-2 p-2 border"
                style={siderBarDivStyle}
                title={navTitle}
              >
                <navOption.icon
                  title={navOption.title}
                  className="me-2"
                  size="18px"
                />
                {navText}
              </NavLink>
            ) : null;
          })}
          <Nav.Link
            as={Button}
            onClick={onClick}
            className="bg-danger p-2 w-100 rounded mb-2 border"
            style={{ textDecoration: "none", color: "#000" }}
            title="Logout From Blindweight"
          >
            <FaSignInAlt size="18px" />
            &nbsp; Logout
          </Nav.Link>
        </>
      )}
    </>
  );
};

const siderBarDivStyle = ({ isActive }) => ({
  color: isActive ? "#fff" : "#313333",
  background: isActive ? "#4d90fe" : "#fff",
});

export default SideBarOptions;
