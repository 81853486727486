import { useLocation, useNavigate as useHistory } from "react-router-dom";

const useNavigate = () => {
  const navigate = useHistory();
  const location = useLocation();

  const navigateWithRedirect = (to, options = {}, redirectUrl) => {
    const redirectBackUrl = redirectUrl ? redirectUrl : location.pathname;

    navigate(to, {
      ...options,
      state: {
        ...options.state,
        redirectUrl: redirectBackUrl,
      },
    });
  };

  return navigateWithRedirect;
};

export default useNavigate;
