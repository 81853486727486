let CLIENT_URL = "";
let BASE_URL = "";
let BASE_UPLOAD_URL = "";
let STRIPE_PUBLISHABLE_KEY = "";

if (
  process.env.NODE_ENV === "production" &&
  document.location.href.includes("https://app.blindweight.com")
) {
  CLIENT_URL = "https://app.blindweight.com";
  BASE_URL = "https://app.blindweight.com:8443/blindweight/api/v1";
  BASE_UPLOAD_URL = "https://app.blindweight.com:8443";

  STRIPE_PUBLISHABLE_KEY =
    "pk_live_51LiWctGf6vXntSVTvaMabbKtCnsoW2UgTMLaNCfdKUXYPjQU5E6hL7Fny2hlihnvro8bKU2gO4XQDq4XA1TzmAJb00n7NPV2yL";
} else {
  if (document.location.href.includes("https://staging.blindweight.com")) {
    CLIENT_URL = "https://staging.blindweight.com";
    BASE_URL = "https://staging.blindweight.com:4100/blindweight/api/v1";
    BASE_UPLOAD_URL = "https://staging.blindweight.com:4100";

    STRIPE_PUBLISHABLE_KEY =
      "pk_live_51LiWctGf6vXntSVTvaMabbKtCnsoW2UgTMLaNCfdKUXYPjQU5E6hL7Fny2hlihnvro8bKU2gO4XQDq4XA1TzmAJb00n7NPV2yL";
    // STRIPE_PUBLISHABLE_KEY =
    //   "pk_test_51LiWctGf6vXntSVTmk2oyOHqx9m4Z3cOIM8v1c7N3dWuBai1XjmgSw3MiB66ktKfZH7SpFVQsum77HAGL9y4u9cX00tzToZ8A6";
  } else {
    CLIENT_URL = "http://localhost:3000";
    BASE_URL = "http://localhost:4000/blindweight/api/v1";
    BASE_UPLOAD_URL = "http://localhost:4000";

    STRIPE_PUBLISHABLE_KEY =
      "pk_test_51JE5zdSE48tZZxCyMJIOWpMR5Ms1CDNzxcyYQj5O8DOShMKIGBTJUTZKDgwpgXZCw00wBXIELtKGefWTHuixNN0800w6Ys8y4S";
  }
}

export { CLIENT_URL, BASE_URL, BASE_UPLOAD_URL, STRIPE_PUBLISHABLE_KEY };
