import axios from "axios";

import { BASE_URL } from "../config/config";
import { isHTML } from "../utils/utilFunctions";

const apiRequests = async ({
  url = "",
  method = "GET",
  authTokenRequired = false,
  multiPart = false,
  post_object = {},
}) => {
  let responseObject = {};
  if (url === "") return;

  // Create a new AbortController if one isn't provided
  try {
    const axiosObject = {
      method: method,
      url: url,
      withCredentials: authTokenRequired,
      // signal: requestSignal, // Pass the signal to Axios
    };
    if (method === "POST") {
      axiosObject.data = post_object;
      if (multiPart) {
        axiosObject.header = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
      }
    }
    const response = await axios({ ...axiosObject });

    let data = response.data;
    responseObject = { statusCode: response.status, ...data };
  } catch (e) {
    if (axios.isCancel(e)) {
      responseObject = {
        status: false,
        statusCode: 499, // Custom status code for client closed request
        message: "Request was cancelled.",
      };
    } else if (e.response) {
      let data = e.response.data;
      if (e.response.status === 404 && isHTML(e.response.data)) {
        responseObject = {
          status: false,
          statusCode: e.response.status,
          message: "Please enter a valid fetch api url.",
        };
      } else {
        responseObject = { statusCode: e.response.status, ...data };
      }
    } else {
      responseObject = {
        status: false,
        statusCode: 500,
        message: "Something went wrong!!!",
      };
    }
  }
  return responseObject;
};

export const fetchPaginatedData = async (fetch_url) => {
  let errorResponseObject = {
    status: false,
    data: [],
    total_pages: 1,
    total_items: 0,
    page_no: 1,
    message: "Something went wrong.",
  };
  if (!fetch_url || fetch_url === "") {
    errorResponseObject.message = "Please provide fetch api url.";
    return errorResponseObject;
  }
  const response = await apiRequests({
    method: "GET",
    url: fetch_url,
    authTokenRequired: true,
  });
  if (response.status) {
    return response;
  } else {
    errorResponseObject.message = response.message;
    errorResponseObject.statusCode = response.statusCode;
    return errorResponseObject;
  }
};

export const getTermsAndConditions = async () => {
  const response = await apiRequests({
    method: "GET",
    url: `${BASE_URL}/terms/getTermsAndConditions`,
  });
  if (response.termDetails) return response.termDetails;
};

export const changeTermsAndConditionsStatus = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/terms/changeTermsStatus`,
  });
};

export const updateTermsAndConditions = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/terms/updateTermsAndConditions`,
  });
};

export const getPopupSettingBySlugValue = async (slug) => {
  return await apiRequests({
    method: "GET",
    url: `${BASE_URL}/setting/getPopupSettingBySlugValue/${slug}`,
  });
};

export const verifyUserEmailRequest = async ({ user_id = "", token = "" }) => {
  return await apiRequests({
    method: "GET",
    url: `${BASE_URL}/user/verify-email/${user_id}/${token}`,
  });
};

export const sendTokenLinkThroughEmail = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/user/sendResetLink`,
  });
};

export const userPasswordReset = async ({ post_object, user_id, token }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/user/password-reset/${user_id}/${token}`,
  });
};

export const checkRegistrationPaymentSuccess = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/payment/registration-success`,
  });
};

export const createPaymentIntent = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/payment/create-payment-intent`,
  });
};

export const createSubscriptionSession = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/payment/create-checkout-session`,
  });
};

export const checkSubscriptionStatus = async ({ post_object }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/payment/check-stripe-session-status`,
  });
};

/*** USER routes STARTS ***/
export const sendBroadcastEmail = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/admin/sendBroadcastEmail`,
  });
};

export const logBWUserIn = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/user/loginUser`,
  });
};

export const signUpUser = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/signUpUser`,
  });
};

export const signUpAndUpdateUser = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/signupAndUpdateUser`,
  });
};

export const checkSignedUpCookie = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/check-signedup-cookie`,
  });
};

export const getUserLoginCount = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/loginCount`,
  });
};

export const deactivateMyUserAccount = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/deactivateAccount`,
  });
};

export const getUserPreferences = async () => {
  const response = await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/setting/fetchUserPreferences`,
  });
  if (response.status) {
    return response.data;
  }
};

export const getPopupSettings = async () => {
  const response = await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/setting/getAllPopupsSetting`,
  });
  if (response.status) {
    return response.popup_list;
  }
};

export const getPopupSettingsBySlug = async ({ slug }) => {
  if (!slug) return;
  const response = await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/setting/getPopupSettingBySlugValue/${slug}`,
  });
  if (response.status) {
    return response.popup_data;
  }
};

export const addOrUpdatePopupSetting = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/setting/addOrUpdatePopupSetting`,
  });
};

export const getBlockedUsersList = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/blockUserList`,
  });
};

export const updateBlockedUsersList = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/blockUserList`,
  });
};

export const updateUserPreferences = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/setting/updateUserPreferences`,
  });
};

export const getUserProfileSetting = async ({ setting_name }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getUserProfileSetting/${setting_name}`,
  });
};

export const changeUserStatus = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/admin/toggleUserStatus`,
  });
};

export const changeUserProfileSetting = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/changeUserSetting`,
  });
};

export const checkUserEmailVerified = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/checkUserEmailVerified`,
  });
};

export const getUserDetails = async ({ user_id = "" }) => {
  const response = await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getUserDetails/${user_id}`,
  });
  if (response.status) {
    return response.userDetails;
  }
};

export const updateMySettings = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/update-my-settings`,
  });
};

export const updateUserData = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/admin/updateUser`,
  });
};

export const fetchUsersAllDetails = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/fetchAllUserDetails`,
  });
};

export const fetchUsersExportData = async ({ query_string = "" }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/export/userList?${query_string}`,
  });
};

export const fetchCodeExportData = async ({ query_string = "" }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/export/codeList?${query_string}`,
  });
};

export const checkCodePermission = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/checkCodePermission`,
  });
};

export const fetchUsersRecentLogs = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/admin/fetchRecentLogs`,
  });
};

export const deleteUserDetails = async ({ user_id = "" }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/admin/deleteUser/${user_id}`,
  });
};
/*** USER routes ENDS ***/

/*** USER record api routes STARTS ***/
export const getRecordDetails = async ({ record_id = "" }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getCurrentUserRecord/${record_id}`,
  });
};

export const deleteRecordDetails = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/deleteUserRecord`,
  });
};

export const restoreUserRecord = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/restoreUserRecord`,
  });
};

export const updateUserAccess = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/user/updateUserAccess`,
  });
};

export const updateMultipleRecordInitials = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/updateMultipleCodeRecordsUserInitial`,
  });
};

export const shareThisRecord = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/shareThisRecord`,
  });
};

export const updateMultiShareRecords = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/multiShareRecords`,
  });
};

export const updateRecordDetails = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/updateSavedCodeRecords`,
  });
};

export const deleteMultipleUserRecords = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/deleteMultipleUserRecords`,
  });
};

export const restoreMultipleUserRecords = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/restoreMultipleUserRecord`,
  });
};

export const deleteAllUserRecords = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/deleteAllUserRecords`,
  });
};

export const getAssociatedInitials = async () => {
  const response = await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getAssociatedInitials`,
  });
  let initials_list = [];
  if (response.status) {
    initials_list = response.initials_list;
  }
  return initials_list;
};

export const getUserInitialsList = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/fetchUserInitialsList`,
  });
};

export const getSharedToUserNameList = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getSharedToUserNameList`,
  });
};

export const getLinkedUserNameList = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getLinkedUserNameList`,
  });
};

export const checkCodeAndDecode = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/checkCode`,
  });
};

export const saveCodeRecords = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/saveCodeRecords`,
  });
};

export const sendUserCodes = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    authTokenRequired: true,
    post_object,
    url: `${BASE_URL}/user/sendUserCodes`,
  });
};

export const submitAnIssue = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    multiPart: true,
    url: `${BASE_URL}/user/submit-issue`,
  });
};
export const createANewUser = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/admin/createUser`,
  });
};

export const shareAllCodes = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/shareAllCodeRecords`,
  });
};

export const sendCodeAccessResetLink = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/sendCodeAccessResetLink`,
  });
};

export const getGroupUser = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getGroupUser`,
  });
};

// export const getGroupUserList = async ({ query = "" }) => {
//   return await apiRequests({
//     method: "GET",
//     authTokenRequired: true,
//     url: `${BASE_URL}/user/getGroupUserList?${query}`,
//   });
// };

export const getGroupInvites = async ({ query = "" }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getGroupInvites?${query}`,
  });
};

export const checkUserWithEmail = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/checkUserWithEmail`,
  });
};

export const sendGroupUserInvite = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/inviteExistingUserToGroup`,
  });
};
export const sendGroupNewUserInvite = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/inviteGroupUser`,
  });
};

export const respondToGroupInvite = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/respondToGroupInvite`,
  });
};

export const updateCodeAccessPassword = async ({ data }) => {
  const { user_id, token, ...post_object } = data;
  return await apiRequests({
    method: "POST",
    post_object,
    url: `${BASE_URL}/user/updateCodeAccessPassword/${user_id}/${token}`,
  });
};

export const getNotificationList = async ({ page_no, page_limit }) => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/getNotificationList?page_no=${page_no}&page_limit=${page_limit}`,
  });
};

export const markNotificationRead = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/markNotificationRead`,
  });
};

export const markAllNotificationRead = async ({ post_object = {} }) => {
  return await apiRequests({
    method: "POST",
    post_object,
    authTokenRequired: true,
    url: `${BASE_URL}/user/markAllNotificationRead`,
  });
};

export const checkGroupUserType = async () => {
  return await apiRequests({
    method: "GET",
    authTokenRequired: true,
    url: `${BASE_URL}/user/checkGroupUserType`,
  });
};

/*** USER record api routes ENDS ***/
