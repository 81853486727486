import { useEffect, useState } from "react";

import { checkGroupUserType } from "../../API/apiCalls";

const useCheckGroupAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [groupAdmin, setGroupAdmin] = useState(false);

  useEffect(() => {
    const checkForAccesstype = async () => {
      const data = await checkGroupUserType();
      if (data.status && data.groupAdmin) {
        setGroupAdmin(true);
      }
      setIsLoading(false);
    };

    if (isLoading) checkForAccesstype();
  }, [isLoading]);

  return { groupAdmin, isLoading };
};

export default useCheckGroupAdmin;
