import * as DOMPurify from "dompurify";
import { Toast, ToastContainer } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import { FiMessageCircle } from "react-icons/fi";
import { BsExclamationTriangle } from "react-icons/bs";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

import "./toast.styles.css";
import { useOurContext } from "../Context/OurContext";

const ToastContainerDiv = () => {
  const { toastList, removeToastItem } = useOurContext();

  function createToastHTMLMsg(msg) {
    let terms_text = msg ? msg : "";
    const sanitizedHTML = DOMPurify.sanitize(terms_text);

    return { __html: sanitizedHTML };
  }

  return (
    <div className="toast-container-div w-100">
      <ToastContainer className="me-2 mt-4" position="top-end">
        <AnimatePresence>
          {toastList &&
            toastList.map((item) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, x: 80 }} // Initial animation state
                animate={{ opacity: 1, x: 0 }} // Animation when added or updated
                exit={{ opacity: 0, x: -100 }} // Animation when removed
                transition={{ duration: 0.3 }} // Animation duration
                onClose={() => removeToastItem(item.id)}
                className={`rounded-3 toast-item-container toast-item-container-${item.type}`}
              >
                <Toast
                  className="toast-item-div bg-light"
                  onClose={() => removeToastItem(item.id)}
                >
                  <Toast.Header className="w-100 d-flex justify-content-evenly">
                    <div>
                      <div
                        className={`toast-icon rounded-circle text-center d-flex justify-content-center align-items-center toast-icon-${item.type}`}
                      >
                        {item.type === "info" && <FiMessageCircle />}
                        {item.type === "success" && <FaRegCheckCircle />}
                        {item.type === "error" && <FaRegTimesCircle />}
                        {item.type === "warning" && <BsExclamationTriangle />}
                      </div>
                    </div>
                    <div className="toast-item-div d-flex flex-column">
                      <div
                        className={`toast-header py-1 toast-header-${item.type}`}
                      >
                        <strong
                          className="m-0 p-0 w-100"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "transparent",
                          }}
                        >
                          {item.heading}
                        </strong>
                      </div>
                      <div
                        className="toast-body py-1"
                        dangerouslySetInnerHTML={createToastHTMLMsg(
                          item.message,
                        )}
                      >
                        {/* {item.message} */}
                      </div>
                    </div>
                  </Toast.Header>
                </Toast>
              </motion.div>
            ))}
        </AnimatePresence>
      </ToastContainer>
    </div>
  );
};

export default ToastContainerDiv;
