import React from "react";
import { createRoot } from "react-dom/client";
import Chart from "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.css";
import App from "./App";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (process.env.NODE_ENV === "development") {
  root.render(<App />);
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
