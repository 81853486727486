import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import { OurContextProvider } from "./components/Context/OurContext";

import ProtectedRoutes from "./components/ProtectedRoutes";
import LoaderSpinner from "./components/LoaderSpinner/LoaderSpinner";
import { QueryProvider } from "./lib/react-query/QueryProvider";

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const MySettings = React.lazy(() => import("./pages/Settings/MySettings"));
const CodeRecords = React.lazy(() => import("./pages/CodeRecords"));
const GroupUsers = React.lazy(() => import("./pages/Group/Users"));
const GroupInvites = React.lazy(() => import("./pages/Group/Invites"));
const UserCodes = React.lazy(() => import("./pages/Group/UserCodes"));
const ManageUsers = React.lazy(() => import("./pages/ManageUsers/ManageUsers"));
const ManageAdmins = React.lazy(() =>
  import("./pages/ManageAdmins/ManageAdmins"),
);
// const CreditBalance = React.lazy(() =>
//   import("./pages/CreditBalance/CreditBalance"),
// );
const UserStats = React.lazy(() => import("./pages/UserStats/UserStats"));
const ManageTerms = React.lazy(() => import("./pages/ManageTerms/ManageTerms"));
const ManagePopups = React.lazy(() =>
  import("./pages/ManagePopups/ManagePopups"),
);
const AccessLogs = React.lazy(() => import("./pages/AccessLogs/AccessLogs"));
const Support = React.lazy(() => import("./pages/Support/Support"));
const Broadcast = React.lazy(() => import("./pages/Broadcast"));

const EditUser = React.lazy(() =>
  import("./pages/ManageUsers/EditUser/EditUser"),
);
const EditAdmin = React.lazy(() =>
  import("./pages/ManageAdmins/EditAdmin/EditAdmin"),
);

const PreferencesSettings = React.lazy(() =>
  import("./pages/Settings/PreferencesSettings"),
);
const SignUp = React.lazy(() => import("./pages/SignUp"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const VerifyUserEmail = React.lazy(() =>
  import("./pages/VerifyUserEmail/VerifyUserEmail"),
);
const PageNotFound = React.lazy(() =>
  import("./pages/PageNotFound/PageNotFound"),
);
const PasswordReset = React.lazy(() =>
  import("./pages/PasswordReset/PasswordReset"),
);
const CodeAccessReset = React.lazy(() => import("./pages/CodeAccessReset"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const PaymentSuccess = React.lazy(() =>
  import("./pages/PaymentSuccess/PaymentSuccess"),
);
const Notifications = React.lazy(() => import("./pages/Notifications"));
// const CameraContainer = React.lazy(() =>
//   import("./components/Camera/CameraContainer"),
// );

const App = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <QueryProvider>
          <OurContextProvider>
            <Suspense
              fallback={
                <div className="mt-5">
                  <LoaderSpinner />
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-up/:user_id" element={<SignUp />} />
                <Route
                  path="/verify-email/:userId/:token"
                  element={<VerifyUserEmail />}
                />
                <Route
                  path="/password-reset/:userId/:token"
                  element={<PasswordReset />}
                />
                <Route
                  path="/code-access-reset/:userId/:token"
                  element={<CodeAccessReset />}
                />

                <Route
                  path="/payment-success/:user_id/:payment_type/:prev_page"
                  element={<PaymentSuccess />}
                />
                <Route
                  path="blindweight_privacy_policy"
                  element={<PrivacyPolicy />}
                />

                <Route
                  path="/"
                  element={
                    <ProtectedRoutes
                      accessible_to={[
                        "admin",
                        "parent",
                        "provider",
                        "group",
                        "user",
                      ]}
                    />
                  }
                >
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="my-settings" element={<MySettings />} />
                  <Route path="support" element={<Support />} />
                  <Route path="privacy_policy" element={<PrivacyPolicy />} />
                  <Route path="users" element={<GroupUsers />} />
                  <Route path="notifications" element={<Notifications />} />
                </Route>

                <Route
                  path="/"
                  element={
                    <ProtectedRoutes
                      accessible_to={["parent", "provider", "admin", "group"]}
                    />
                  }
                >
                  <Route path="preferences" element={<PreferencesSettings />} />
                </Route>

                <Route
                  path="/"
                  element={
                    <ProtectedRoutes
                      accessible_to={["parent", "provider", "user", "group"]}
                    />
                  }
                >
                  <Route path="group-invites" element={<GroupInvites />} />
                  <Route path="code-records" element={<CodeRecords />} />
                  <Route path="code-records/:user_id" element={<UserCodes />} />
                </Route>

                <Route
                  path="/"
                  element={<ProtectedRoutes accessible_to={["admin"]} />}
                >
                  <Route path="manage-users" element={<ManageUsers />} />
                  <Route path="manage-admins" element={<ManageAdmins />} />
                  <Route path="edit-user/:user_id" element={<EditUser />} />
                  <Route path="edit-admin/:user_id" element={<EditAdmin />} />
                  <Route path="access-logs" element={<AccessLogs />} />
                  <Route path="statistics" element={<UserStats />} />
                  {/* <Route path='credits' element={<CreditBalance />} /> */}
                  <Route path="manage-terms" element={<ManageTerms />} />
                  <Route path="manage-popups" element={<ManagePopups />} />
                  <Route path="broadcast" element={<Broadcast />} />
                  {/* <Route path="scan-to-code" element={<CameraContainer />} /> */}
                </Route>

                <Route path="*" element={<Navigate to="not-found" />} />
                <Route path="/not-found" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </OurContextProvider>
        </QueryProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
};

export default App;
