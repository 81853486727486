import { motion } from "framer-motion";
import { Navbar } from "react-bootstrap";
import { BsGearWideConnected } from "react-icons/bs";

import "./sidebar.styles.css";
import Link from "../Common/Link";
import { useOurContext } from "../Context/OurContext";
import SideBarOptions from "../SideBarOptions/SideBarOptions";

const SideBar = ({ handleLogout }) => {
  const { authenticatedUser } = useOurContext();

  return (
    <main
      className="sidebar-div bg-dark d-md-flex d-lg-flex flex-column justify-content-between d-sm-none d-none"
      style={{ position: "relative" }}
    >
      <Navbar
        bg="dark"
        className="p-2 d-flex flex-column align-items-center border-right-1 border-primary"
      >
        <SideBarOptions onClick={handleLogout} />
      </Navbar>

      <div className="px-2 pb-3 w-100 rounded mb-2 text-center text-light">
        <Link
          to="/my-settings"
          className="text-light"
          style={{ textDecoration: "none" }}
        >
          <span className="position-relative pointer-cursor">
            Settings
            <motion.div
              className="p-2 position-absolute d-flex justify-content-center align-items-center"
              style={{ top: "-5px", right: "-30px" }}
              whileHover={{ rotate: 720, transition: { duration: 2 } }}
            >
              <BsGearWideConnected />
            </motion.div>
          </span>
        </Link>
        <div style={{ textTransform: "capitalize" }}>
          {authenticatedUser ? authenticatedUser.user_access : ""} Portal
        </div>
        <div style={{ fontSize: "12px" }}>
          <Link
            to="/privacy_policy"
            className="text-light"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </main>
  );
};

export default SideBar;
