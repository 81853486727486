export const getChartLabelSeriesFor2Dates = (
  start_date,
  end_date,
  display_interval,
) => {
  const from_date = new Date(start_date);
  const to_date = new Date(end_date);
  const start_year = from_date.getFullYear();
  const end_year = to_date.getFullYear();
  const start_month = from_date.getMonth();
  const end_month = to_date.getMonth();

  let labelSeriesArray = [];

  let month_start_value = start_month;
  let month_end_value = 11;
  for (let i = start_year; i <= end_year; i++) {
    if (display_interval === "yearly") {
      labelSeriesArray.push({ year: i });
    } else {
      if (i === end_year) month_end_value = end_month;

      for (let j = month_start_value; j <= month_end_value; j++) {
        if (display_interval === "daily") {
          let start_date = from_date.getDate();
          let end_date = to_date.getDate();
          const lastDayOfPreviousMonth = new Date(i, j + 1, 0);
          start_date = j === start_month ? start_date : 1;
          end_date =
            j === end_month ? end_date : lastDayOfPreviousMonth.getDate();
          for (let k = start_date; k <= end_date; k++) {
            // for (let k = 1; k <= lastDayOfPreviousMonth.getDate(); k++) {
            labelSeriesArray.push({ year: i, month: j + 1, day: k });
          }
        }
        if (display_interval === "monthly") {
          labelSeriesArray.push({ year: i, month: j + 1 });
        }
      }
      month_start_value = 0;
    }
  }

  return labelSeriesArray;
};

export const divideArrayByProperty = (arr, property) => {
  return arr.reduce((result, obj) => {
    const key = obj[property];
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(obj);
    return result;
  }, {});
};

// Still need to check if this part is working STARTS
export const sortArrayOfObjectsByNumber = (data, field, order) => {
  return data.sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];

    if (aValue === undefined || aValue === null) return 1;
    if (bValue === undefined || bValue === null) return -1;

    let sorted_order;
    if (order === "asc") {
      sorted_order = aValue - bValue;
    } else if (order === "desc") {
      sorted_order = bValue - aValue;
    }
    return sorted_order;
  });
};
// Still need to check if this part is working ENDS

export const sortArrayOfObjectsByText = (data, field, order) => {
  return data.sort((a, b) => {
    const aValue = a[field]?.toLowerCase();
    const bValue = b[field]?.toLowerCase();

    if (!aValue) return 1;
    if (!bValue) return -1;

    let sorted_order;
    if (order === "asc") {
      sorted_order = aValue.localeCompare(bValue);
    } else if (order === "desc") {
      sorted_order = bValue.localeCompare(aValue);
    }
    return sorted_order;
  });
};

export const sortArrayOfObjectsByDate = (data, field, order) => {
  return data.sort((a, b) => {
    const aValue = new Date(a[field]);
    const bValue = new Date(b[field]);

    if (isNaN(aValue.getTime()) || isNaN(bValue.getTime())) {
      return 0;
    }

    let sorted_order;
    if (order === "asc") {
      sorted_order = aValue - bValue;
    } else if (order === "desc") {
      sorted_order = bValue - aValue;
    }
    return sorted_order;
  });
};

export const isHTML = (content) => {
  // Create a temporary element
  const tempElement = document.createElement("div");
  tempElement.innerHTML = content;
  // Check if the innerHTML of the temporary element is different from the original content
  // This indicates that the content was successfully parsed as HTML
  return tempElement.innerHTML !== content;
};

export const validUserName = (user_name) => {
  let returnObject = { status: true, message: "Username is valid" };
  if (user_name === "") {
    returnObject = { status: false, message: "Username cannot be empty" };
  } else if (!/^[a-zA-Z][_a-zA-Z0-9]{2,29}$/.test(user_name)) {
    returnObject = { status: false, message: "Username is invalid" };
  }

  return returnObject;
};

export const compareAreObjectsSame = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const WEIGHT_IN_KG_PER_LBS = 0.453592;
export const convertWeightFromLbsToKg = (weight) => {
  return Math.abs(weight * WEIGHT_IN_KG_PER_LBS).toFixed(2);
};

export const arraysHaveSameValues = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};

export const isEduEmail = (email) => {
  // Regular expression to match .edu emails
  const eduEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.edu$/;
  if (eduEmailRegex.test(email)) {
    return true; // Valid .edu email
  } else {
    return false; // Invalid email
  }
};
