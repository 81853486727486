import React from "react";
import { Link as BootstrapLink, useLocation } from "react-router-dom";

const Link = ({ to, state = {}, redirectUrl, ...otherProps }) => {
  const location = useLocation();

  const redirectBackUrl =
    redirectUrl && redirectUrl ? redirectUrl : location.pathname;

  // Add redirectUrl to the state of the Link
  const linkState = {
    ...state,
    redirectUrl: redirectBackUrl,
  };

  return <BootstrapLink to={to} state={linkState} {...otherProps} />;
};

export default Link;
