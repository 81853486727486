import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import Swal from "sweetalert2";
import { formatISO } from "date-fns";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import "./protected-routes.css";
import SideBar from "./SideBar/SideBar";
import useNavigate from "../hooks/useNavigate";
import { useOurContext } from "./Context/OurContext";
import HeadingNavbar from "./HeadingNavbar/HeadingNavbar";
import LoaderSpinner from "./LoaderSpinner/LoaderSpinner";
import ToastContainerDiv from "./Toast/ToastContainerDiv";
import { LogUserActions } from "../functionalities/loggedActions";
import {
  onMessageListener,
  saveMessagingDeviceToken,
} from "../config/firebase-messaging";
import { getUserPreferences } from "../API/apiCalls";

const LazyOutletWrapper = lazy(() => import("./OutletWrapper"));

const ProtectedRoutes = ({ accessible_to = ["user"] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, removeCookie] = useCookies(["blind_weight"]);
  const headingRef = useRef(null);
  const [headingHeight, setHeadingHeight] = useState(50);
  const {
    updateToastData,
    authenticatedUser,
    isAuthLoading,
    myPreferences,
    setUserPreferences,
    logoutAuthenticatedUser,
  } = useOurContext();

  const getUserSettings = async () => {
    const data = await getUserPreferences();
    if (data && data.user) setUserPreferences({ ...data.user });
  };

  useEffect(() => {
    if (!isAuthLoading) {
      if (!authenticatedUser) {
        navigate("/login");
      } else {
        if (
          authenticatedUser &&
          !accessible_to.includes(authenticatedUser.user_access)
        ) {
          navigate("/dashboard");
        } else if (authenticatedUser) {
          if (!myPreferences) getUserSettings();
          saveMessagingDeviceToken(
            authenticatedUser.id,
            formatISO(new Date(authenticatedUser.exp * 1000)),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isAuthLoading]);

  useEffect(() => {
    if (authenticatedUser) handleRouteAccessLog(authenticatedUser.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  onMessageListener()
    .then((payload) => {
      const { title, body } = payload.data;
      const notification = {
        type: "info",
        heading: title,
        message: body,
      };
      updateToastData({ data: notification, delay: 3000 });
    })
    .catch((err) => console.log("error: ", err));

  useEffect(() => {
    if (headingRef.current !== null) {
      setHeadingHeight(headingRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headingRef.current]);

  const handleRouteAccessLog = async (user_id) => {
    const log_message = `Route Access - ${location.pathname}`;
    const log_type = "page_access";
    await LogUserActions(user_id, log_message, log_type);
  };

  const handleLogout = async () => {
    const warning = await Swal.fire({
      icon: "warning",
      title: "Warning",
      text: "Are you sure, you want to Logout?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    });
    if (!warning.isConfirmed) {
      return;
    }
    removeCookie("blind_weight");
    logoutAuthenticatedUser();
    navigate("/login");
  };

  return (
    authenticatedUser && (
      <main style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
        <HeadingNavbar
          ref={headingRef}
          onClick={handleLogout}
          userName={authenticatedUser.name}
          userType={authenticatedUser.user_access}
        />
        <div
          className="content"
          style={{ height: `calc(100vh - ${headingHeight}px)`, width: "100%" }}
        >
          <SideBar
            userName={authenticatedUser.name}
            userType={authenticatedUser.user_access}
            handleLogout={handleLogout}
          />
          <div
            className="dashboard-main"
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <ToastContainerDiv />
            <Suspense
              fallback={
                <div className="mt-5">
                  <LoaderSpinner />
                </div>
              }
            >
              <LazyOutletWrapper />
            </Suspense>
          </div>
        </div>
      </main>
    )
  );
};

export default ProtectedRoutes;
